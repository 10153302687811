@import './mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Roboto+Mono&display=swap');
.App {
  box-sizing: border-box;
  display: flex;
  font-size: 1.2rem;
  flex-direction: row;
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
  text-align: center;
}
#info {
  background-color: rgba(88, 24, 69, 0.01);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  padding: 3rem;
  position: fixed;
  text-align: left;
  width: 35%;
  h1 {
    margin-bottom: 15%;
    margin-top: 40%;
  }
  p {
    color: rgb(88, 24, 69);
    margin-bottom: 15%;
  }
  a { margin-right: 1.2rem; }
  button {
    border: 1px solid;
    border-radius: 5px;
    font-family: monospace;
    font-size: 1rem;
    color: rgb(88, 24, 69);
    padding: 0.7rem;
    @include background-transition;
    &:hover {
      background-color: rgb(88, 24, 69);
      color: white;
    }
  }
}
.social { margin-bottom: 10%; }
#projects {
  left: 35%;
  position: relative;
  top: 0%;
  width: 65%;
}
#section-1 {
  background-color: rgb(218, 247, 166);
  color: rgb(88, 24, 69);
}
#section-1 a { color: rgb(88, 24, 69); }
#section-2 { background-color: rgb(88, 24, 69); }
#section-3 { background-color: rgb(199, 0, 57); }
#section-4 { background-color: rgb(144, 12, 63); }
#section-5 {
  background-color: rgb(255, 195, 0);
  color: rgb(88, 24, 69) !important;
  img {
    height: 491px !important;
    width: 264px !important;
  }
  a {
    color: rgb(88, 24, 69) !important;
  }
} 
#section-6 { background-color: rgb(255, 87, 51); }
.project-tile {
  color: rgb(245, 230, 240);
  height: 100vh;
  padding: 1rem 7rem 3rem 7rem;
  img {
    display: block;
    margin: 0 auto;
  }
  p {
    font-weight: 100;
    padding-bottom: 1.5rem;
  }
  span { margin-right: 1rem; }
  a {
    border: 1px solid;
    border-radius: 5px;
    color: rgb(245, 230, 240);
    font-weight: 100;
    text-decoration: none;
    padding: 0.5rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    &:hover {
      background-color: white;
      border: none;
      color: rgb(89, 50, 126);
    }
  }
}
#publications {
  background-color: rgb(218, 247, 166);
  padding: 1rem 7rem 3rem 7rem;
  h2 { margin-bottom: 2.5rem; }
}
.pubs {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  img { margin-right: 1.5rem; }
  a {
    color: black;
    font-size: 1rem !important;
    text-align: left;
    text-decoration: none;
    &:hover { text-decoration: underline; }
  }
}
#footer {
  background-color: black;
  color: white;
  padding: 1rem;
}

@media only screen and (max-width: 992px) {
  .App { flex-direction: column; }
  #info {
    height: 100vh;
    position: static;
    text-align: center;
    width: 100%;
  }
  #info h1 {
    margin-bottom: 2rem;
    margin-top: 5rem;
  }
  #info p {
    color: rgb(88, 24, 69);
    margin-bottom: 2rem;
  }
  #projects {
    position: static;
    width: 100%;
  }
  .project-tile { padding: 1rem 1rem 3rem 1rem; }
  #section-5 { height: 120vh; }
  #publications { padding: 1rem; }
  .pubs a { line-height: 2; }
}

@media only screen and (max-width: 468px) {
  .project-tile { height: 110vh; }
  .project-tile img {
    height: 300px !important;
    width: 300px !important;
  }
  #section-5 { height: 140vh; }
  #section-6 { height: 100vh; }
}